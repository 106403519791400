import { ref } from 'vue'

export function useRichTextLineLimit() {
  const blockLevelElements = ref(['</address>', '</article>', '</aside>', '</blockquote>', '</canvas>', '</dd>', '</div>', '</dl>', '</dt>', '</fieldset>', '</figcaption>', '</figure>', '</footer>', '</form>', '</h1>', '</h2>', '</h3>', '</h4>', '</h5>', '</h6>', '</header>', '</hr>', '</li>', '</main>', '</nav>', '</noscript>', '</ol>', '</p>', '</pre>', '</section>', '</table>', '</tfoot>', '</ul>', '</video>', '</br>'])

  function validateRichTextLineLimit(value, limit) {
    const lineCount = blockLevelElements.value.reduce((count, element) => {
      if (value.includes(element)) {
        const regx = new RegExp(element, 'gi');
        const elementCount = (value.match(regx) || []).length;
        count += elementCount
      }
      return count
    }, 0)
    return lineCount <= limit
  }


  return { validateRichTextLineLimit }
}
