<template>
  <div>
    <template v-if="getColorContrastRatio">
      <a role="button" href="javascript:void(0)" :id="`${name}-color-contrast-tooltip-button`"
        ><BIconExclamationTriangleFill class="text-warning" font-scale="2"
      /></a>
      <b-tooltip :target="`${name}-color-contrast-tooltip-button`" variant="warning"
        >Color contrast is {{ getColorContrastRatio }}:1 <br />
        Recommended is 4.5:1</b-tooltip
      >
    </template>
  </div>
</template>
<script>
import { useColorContrast } from '@/composables/useColorContrast'
import { BIconExclamationTriangleFill } from 'bootstrap-vue'
export default {
  name: 'ColorContrastWarning',
  setup() {
    const { getContrastRatio } = useColorContrast()
    return { getContrastRatio }
  },
  props: {
    bgColor: {
      type: String,
      default: '',
    },
    textColor: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
  },
  components: {
    BIconExclamationTriangleFill,
  },
  computed: {
    getColorContrastRatio() {
      if (this.bgColor && this.textColor) {
        const ratio = this.getContrastRatio(this.bgColor, this.textColor)
        return ratio < 4.5 ? ratio : false
      }
      return false
    },
  },
}
</script>
