export function useColorContrast() {

  const hexToRgb = (hex) => {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b;
    });

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }

  const getLuminanace = (rgbColor) => {
    const rgb = rgbColor.map((value) => {
      const val = value / 255;
      return val <= 0.03928 ? val / 12.92 : ((val + 0.055) / 1.055) ** 2.4;
    });
    return Number((0.2126 * rgb[0] + 0.7152 * rgb[1] + 0.0722 * rgb[2]).toFixed(3));
  };

  const getContrastRatio = (backgroundColor, textColor) => {
    const bgColorToRGB = hexToRgb(backgroundColor)
    const txColorToRGB = hexToRgb(textColor)
    const lumBackgroundColor = getLuminanace(Object.values(bgColorToRGB));
    const lumTextColor = getLuminanace(Object.values(txColorToRGB));

    return ((Math.max(lumBackgroundColor, lumTextColor) + 0.05) / (Math.min(lumBackgroundColor, lumTextColor) + 0.05)).toFixed(2);
  };


  return { getContrastRatio }
}
